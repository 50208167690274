export const isValidGuid = guid => {
  //const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i; // Real GUID
  const guidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i; // CET GUID ?
  return guidPattern.test(guid);
};

export const isValidGenericString = string => {
  return !!string && !!string.replace(/\s/g, '');
};

export const isUserInTeachersList = (audience, userId) => {
  if (!audience.teachers) {
    return false;
  }
  let teachersIds = audience.teachers.map(teacher => teacher.UserID);
  return teachersIds.indexOf(userId) > -1;
};
// export const isActive = (startDate, endDate) => {
//   const today = new Date();
//   return startDate <= today && today <= endDate;
// };
export const isToday = date => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getYear() === today.getYear()
  );
};
export const isTomorrow = date => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return (
    date.getDate() === tomorrow.getDate() &&
    date.getMonth() === tomorrow.getMonth() &&
    date.getYear() === tomorrow.getYear()
  );
};
export const isTodayOrTomorrow = date => {
  return isToday(date) || isTomorrow(date);
};

export const isMobileDevice = () => window.TOC_DEVICE_TYPE === 'MOBILE';
