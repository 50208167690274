import { isValidGuid } from '../utilities/validations';
import { Cache } from '../utilities/cache';

export const ENVIRONMENT_ID_KEY = 'ENVIRONMENT_ID_KEY';
export const AUDIENCE_ID_KEY = 'AUDIENCE_ID_KEY';
export const IS_STUDENT_PREVIEW = 'IS_STUDENT_PREVIEW';
export const RETURN_URL = 'RETURN_URL';
export const IS_REVEALED_BY_TEACHER = 'IS_REVEALED_BY_TEACHER';
export const PRIVATE_PURCHASE_ID = '__private-purchase__';

const fetchGetOptions = {
  method: 'GET',
  headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
  mode: 'cors',
  credentials: 'include',
  cache: 'no-store'
};

export const fetchUserCapsules = async userId => {
  const capsules = fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}GetUserCapsules?userId=${userId}`, fetchGetOptions)
    .then(response => response.json())
    .then(json => json);

  return capsules;
};

export const checkIsUserAuthorized = async (userId, userRole, schoolId, rootFolderId, lang = 'he') => {
  if (userRole === 'admin') {
    return Promise.resolve(true);
  }

  return fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}IsUserAuthorized?rootFolderId=${rootFolderId}&userId=${userId}&schoolId=${schoolId}&lang=${lang}`)
    .then(response => response.text())
    .then(val => val === 'true');
};

export const fetchUserGroups = (userId, schoolId) =>
  fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}GetUserGroups?userId=${userId}&schoolId=${schoolId}`, fetchGetOptions)
    .then(response => response.json())
    .then(json => json);

export const fetchUserAudiences = (productId, userId, schoolId) =>
  fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}GetUserAudiences?userId=${userId}&schoolId=${schoolId}&productId=${productId}`, fetchGetOptions)
    .then(response => response.json())
    .then(json => json);

export const fetchLastVisit = (productId, userId, audienceId) =>
  fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}ProductUserAudienceHistory/${productId}/${userId}/${audienceId}`, { cache: 'no-store' })
    .then(response => response.json())
    .then(json => json)
    .catch(res => {
      console.error(res);
    });

export const updateLastVisitedItem = (productId, userId, audienceId, itemId) => {
  const idParts = itemId ? itemId.split('_') : [];
  const folderId = idParts.length > 0 ? idParts[0] : '';
  const documentId = idParts.length > 1 ? idParts[1] : '';

  if (isValidGuid(folderId) && (!documentId || isValidGuid(documentId))) {
    let data = {
      id: { productId, userId, audienceId },
      lastVisit: { date: Date.now().toString(), id: itemId }
    };

    fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}ProductUserAudienceHistory`, {
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(data)
    }).catch(res => {
      console.error(res);
    });
  }
};

export const getDefaultAudience = audiences => {
  let audience = null;

  if (audiences && audiences.length > 0) {
    audience = audiences[0];

    for (let i = 0; i < audiences.length; i++) {
      if (audiences[i].archiveYear === undefined || audiences[i].archiveYear === null) {
        audience = audiences[i];
        break;
      }
    }
  }

  return audience;
};

export const getActiveAudience = (audiences, audienceId) => {
  let audience = null;

  if (audiences) {
    if (audienceId && audienceId !== 'null') {
      audience = audiences.find(ad => ad.audienceId === audienceId);
    }

    if (!audience || !audienceId) {
      audience = getDefaultAudience(audiences);
    }
  }

  return audience;
};

export const fetchUserTasks = async (user, config) => {
  let url = `${window.tocGlobalEndpoints.treeServiceAPIURL}TreeLMS/GetTocLms/${user.userId}/${user.schoolId}/${config.productId}/${user.activeAudience.audienceId}/${config.loLanguage}/?environmentId=${user.activeAudience.id}`;

  if (user.activeAudience.archiveYear) {
    url += `/${user.activeAudience.archiveYear - 1}`;
  }

  return fetch(url, { cache: 'no-store' })
    .then(response => response.json())
    .then(json => json)
    .catch(err => err);
};

export const fetchUserGrades = async (user, config) => {
  let url = `${window.tocGlobalEndpoints.treeServiceAPIURL}TreeLMS/GetStudentScores/${user.userId}/${user.schoolId}/${config.productId}/${user.activeAudience.audienceId}/${config.loLanguage}/?environmentId=${user.activeAudience.id}`;

  if (user.activeAudience.archiveYear) {
    url += `/${user.activeAudience.archiveYear - 1}`;
  }

  return fetch(url, { cache: 'no-store' })
    .then(response => response.json())
    .then(json => json)
    .catch(err => err);
};

export const fetchAudienceStudents = async audienceId => {
  return fetch(`${window.tocGlobalEndpoints.productPlayerServicesAPIURL}GetAudienceStudents?audienceId=${audienceId}`)
    .then(response => response.json())
    .then(json => json);
};

export const setAudienceId = (productName, audienceId) => {
  Cache.set(`${AUDIENCE_ID_KEY}_${productName.toUpperCase()}`, audienceId, Cache.method.localStorage);
};

export const getAudienceId = productName => {
  return Cache.get(`${AUDIENCE_ID_KEY}_${productName.toUpperCase()}`, Cache.method.localStorage);
};

export const clearAudienceId = productName => {
  Cache.remove(`${AUDIENCE_ID_KEY}_${productName.toUpperCase()}`, Cache.method.localStorage);
};

export const setEnvironmentId = (productName, environmentId) => {
  Cache.set(`${ENVIRONMENT_ID_KEY}_${productName.toUpperCase()}`, environmentId, Cache.method.localStorage);
};

export const getEnvironmentId = productName => {
  return Cache.get(`${ENVIRONMENT_ID_KEY}_${productName.toUpperCase()}`, Cache.method.localStorage);
};

export const clearEnvironmentId = productName => {
  Cache.remove(`${ENVIRONMENT_ID_KEY}_${productName.toUpperCase()}`, Cache.method.localStorage);
};

export const setIsStudentPreview = productName => {
  Cache.set(`${IS_STUDENT_PREVIEW}_${productName.toUpperCase()}`, true, Cache.method.sessionStorage);
};

export const getIsStudentPreview = productName => {
  return Cache.get(`${IS_STUDENT_PREVIEW}_${productName.toUpperCase()}`, Cache.method.sessionStorage) === 'true';
};

export const clearIsStudentPreview = productName => {
  Cache.remove(`${IS_STUDENT_PREVIEW}_${productName.toUpperCase()}`, Cache.method.sessionStorage);
  Cache.remove(`${IS_REVEALED_BY_TEACHER}_${productName.toUpperCase()}`, Cache.method.sessionStorage);
};

export const setIsRevealedByTeacher = (productName, isRevealedByTeacher) => {
  Cache.set(`${IS_REVEALED_BY_TEACHER}_${productName.toUpperCase()}`, isRevealedByTeacher, Cache.method.sessionStorage);
};

export const getIsRevealedByTeacher = productName => {
  return Cache.get(`${IS_REVEALED_BY_TEACHER}_${productName.toUpperCase()}`, Cache.method.sessionStorage);
};

export const setUserReturnURL = (productName, returnURL) => {
  Cache.set(`${RETURN_URL}_${productName.toUpperCase()}`, returnURL, Cache.method.sessionStorage);
};

export const getUserReturnURL = productName => {
  return Cache.get(`${RETURN_URL}_${productName.toUpperCase()}`, Cache.method.sessionStorage);
};

export const clearUserReturnURL = productName => {
  Cache.remove(`${RETURN_URL}_${productName.toUpperCase()}`, Cache.method.sessionStorage);
};

export const isAnyActiveAudience = user => {
  return user?.audiences?.filter(audience => !audience.archiveYear)?.length > 0;
};

export const getUserShopURL = (purchaseConfig, userSchoolSign) => {
  const { shopUrl, schoolShopUrl, schoolsList } = purchaseConfig || {};

  const userShopURL = userSchoolSign && schoolShopUrl && schoolsList?.split(',')?.includes(userSchoolSign) ? schoolShopUrl : shopUrl;
  return userShopURL;
};
