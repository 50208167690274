const getValue = (value) => {
  return (typeof(value) === 'object' ? JSON.stringify(value) : value)
}

const Providers = {
  cookie: {
    get: function (key) {
      const value = '; ' + document.cookie;
      const parts = value.split('; ' + key + '=');
      let retVal = null;

      if (parts.length === 2) {
        retVal = parts.pop().split(';').shift();
        if (retVal === 'null') {
          retVal = null;
        }
      }

      return retVal;
    },
    set: (key, value, expires = '', path = '/', domain = '') => {
      if (expires) {
        // If it's a date
        if (expires instanceof Date) {
          // If it isn't a valid date
          if (isNaN(expires.getTime())) expires = new Date();
        } else {
          expires = new Date(new Date().getTime() + parseInt(expires, 10) * 1000 * 60 * 60 * 24);
        }
      }

      const cookie = `${key}=${getValue(value)};expires=${expires.toGMTString()};path=${path};domain=${domain}`;

      document.cookie = cookie;
    },
    remove: key => {
      const expires = new Date();
      expires.setDate(expires.getDate() - 10);
      Providers.cookie.set(key, '', expires);
    }
  },
  sessionStorage: {
    get: function (key) {
      return window.sessionStorage.getItem(key) ? decodeURI(window.sessionStorage.getItem(key)) : null;
    },
    set: function (key, value) {
      window.sessionStorage.setItem(key, getValue(value));
    },
    remove: function (key) {
      window.sessionStorage.removeItem(key);
    }
  },
  localStorage: {
    get: function (key) {
      return window.localStorage.getItem(key) ? decodeURI(window.localStorage.getItem(key)) : null;
    },
    set: function (key, value) {
      window.localStorage.setItem(key, getValue(value));
    },
    remove: function (key) {
      window.localStorage.removeItem(key);
    }
  }
};

export const Cache = {
  method: { cookie: 'cookie', sessionStorage: 'sessionStorage', localStorage: 'localStorage' },

  get: (key, method = 'localStorage') => {
    //if sessionStorage is enabled, it is a property of window
    if (!window[method]) {
      method = 'cookie';
    }

    return Providers[method].get(key);
  },

  set: (key, value, method = 'localStorage', expires, path, domain) => {
    if (!window[method]) {
      method = 'cookie';
    }

    Providers[method].set(key, value, expires, path, domain);

    return value;
  },

  remove: (key, method = 'localStorage') => {
    if (!window[method]) {
      method = 'cookie';
    }

    Providers[method].remove(key);
  }
};
