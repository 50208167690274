/********************************************************************
  Using the global window object, to enable easy environment switch
********************************************************************/

export const tocInitEndpoints = env => {
  env = env || window.TOC_ENDPOINT_ENV;
  return (window.tocGlobalEndpoints = {
    environmentAPIURL: '//environment' + env + 'cet.ac.il/api/Environment/',
    ofakimAPIURL: '//ebag' + env + 'cet.ac.il/api/',
    treeServiceAPIURL: '//treeservice' + env + 'cet.ac.il/api/',
    productPlayerURL: '//productplayer' + env + 'cet.ac.il/',
    productPlayerServicesAPIURL: '//productplayerservices' + env + 'cet.ac.il/',
    loServicesAPIURL: '//loservices' + env + 'cet.ac.il/loservice/',
    ofakimURL: '//ebag' + env + 'cet.ac.il/',
    myBagUIURL: '//mybagui' + env + 'cet.ac.il/',
    myBagURL: '//mybag.ebag' + env + 'cet.ac.il/',
    myBagApiURL: '//mybagapi' + env + 'cet.ac.il/',
    itemCreationURL: '//lostudio' + env + 'cet.ac.il/studiolo/editor.html',
    cdnURL: '//cdn' + env + 'cet.ac.il/',
    dashboardURL: '//dashboard' + env + 'cet.ac.il/'
  });
};

export const getStaticFileUrl = path => {
  if (!path) {
    return '';
  }
  if (path.toLowerCase().startsWith('http')) {
    return path;
  }

  if (path.toLowerCase().startsWith('/')) {
		return `${window.location.protocol}//${window.location.hostname}${path}`;
  }
};
