import React from 'react';
import ReactDOM from 'react-dom/client';
import { extractDataFromURL } from './SharedLogic/app/services';
import { getIsStudentPreview } from './SharedLogic/user/services';
import { tocInitEndpoints } from './SharedLogic/utilities/endpoints';

// import * as serviceWorker from './serviceWorker';

tocInitEndpoints();
// if (process.env.NODE_ENV.toLocaleLowerCase() !== 'production') {
//   // console.log(window.tocGlobalEndpoints);
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React, { exclude: [/^Connect/, /^InjectIntl/] });
// }

function render(module) {
  const App = module.default;
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(<App />);
}

(async () => {
  window.IS_OLAMON = window.IS_OLAMON === 'true';
  window.IS_CYBERSQUAD = window.location.href.toLocaleLowerCase().includes('cybersquad');

  if (window.IS_OLAMON || window.IS_CYBERSQUAD || window.location.port === '83') {
    window.IS_OLAMON = !window.IS_CYBERSQUAD;
    const user = await window.cet.accessmanagement.getSessionAsync();
    const isUserInStudentView = getIsStudentPreview(extractDataFromURL().productName);

    if ((user?.role === 'teacher' || user?.role === 'admin') && !isUserInStudentView) {
      window.TOC_DEVICE_TYPE = 'PC';
      import('./PC/Components/App').then(module => render(module));
    } else if (window.IS_CYBERSQUAD) {
      import('./Cybint/Containers/App').then(module => render(module));
    } else {
      import(`./SharedComponents/Olamonim/App`).then(module => render(module));
    }
  } else if ((window.TOC_DEVICE_TYPE === 'MOBILE' || window.location.port === '82') && window.location.port !== '81') {
    window.TOC_DEVICE_TYPE = 'MOBILE';
    import('./Mobile/Containers/App').then(module => render(module));
  } else {
    window.TOC_DEVICE_TYPE = 'PC';
    import('./PC/Components/App').then(module => render(module));
  }

  window.TOC_PRODUCT_NAME = window.TOC_PRODUCT_NAME || extractDataFromURL().productName;
  // serviceWorker.unregister();
})();
